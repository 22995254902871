<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="footer-widget">
                    <div class="footer-logo">
                        <img
                            src="assets/img/logo12.png"
                            alt="logo"
                            style="max-width: 7rem"
                        />
                    </div>
                    <p>
                        Our Goal is to make your life easier and give you a great
                        Web Services.
                    </p>
                    <div class="footer-link">
                        <!-- <a routerLink="/">
                            <img src="assets/img/google-play.png" alt="google image">
                        </a>
                        <a routerLink="/">
                            <img src="assets/img/app-store.png" alt="google image">
                        </a> -->
                    </div>
                </div>
            </div>
            <!-- <div class="col-lg-3 col-sm-6">
                <div class="footer-widget pl-90">
                    <h3>Company</h3>
                    <ul>
                        <li><a href="#"><i class="flaticon-right"></i> Advertise</a></li>
                        <li><a href="#"><i class="flaticon-right"></i> Support</a></li>
                        <li><a href="#"><i class="flaticon-right"></i> Marketing</a></li>
                        <li><a href="#"><i class="flaticon-right"></i> Bonus</a> </li>
                        <li><a href="#"><i class="flaticon-right"></i> Contact</a></li>
                    </ul>
                </div>
            </div> -->
            <div class="col-lg-4 col-sm-6">
                <div class="footer-widget pl-90">
                    <h3>Quick Links</h3>
                    <ul>
                        <li>
                            <span routerLink="/" (click)="onClick('home')"
                                ><i class="flaticon-right"></i> Home</span
                            >
                        </li>
                        <li>
                            <span routerLink="/" (click)="onClick('services')"
                                ><i class="flaticon-right"></i> Services</span
                            >
                        </li>
                        <!-- <li><span (click)="onClick('home')"><i class="flaticon-right"></i> Screenshots</span></li>
                        <li><span (click)="onClick('home')"><i class="flaticon-right"></i> Pricing</span></li> -->
                        <li>
                            <span routerLink="/" (click)="onClick('testimonials')"
                                ><i class="flaticon-right"></i>
                                Testimonials</span
                            >
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="footer-widget pl-90">
                    <h3>Social Pages</h3>
                    <ul>
                        <li>
                            <a href="https://www.facebook.com/3waytech"
                                ><i class="flaticon-right"></i> Facebook</a
                            >
                        </li>
                        <li>
                            <a href="https://twitter.com/3WayTech1"
                                ><i class="flaticon-right"></i> Twitter</a
                            >
                        </li>
                        <li>
                            <a
                                href="https://www.linkedin.com/company/3-way-technologies/"
                                ><i class="flaticon-right"></i> Linkedin</a
                            >
                        </li>
                        <li>
                            <a href="https://www.instagram.com/3waytech/"
                                ><i class="flaticon-right"></i> Instagram</a
                            >
                        </li>
                        <li>
                            <a href="https://wa.me/+919998692099"
                                ><i class="flaticon-right"></i> whatsapp</a
                            >
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="copyright-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-4 lh-1">
                    <!-- <i class="bi bi-twitter mx-2"></i>
                    <i class="bi bi-linkedin mx-2"></i>
                    <i class="bi bi-facebook mx-2"></i>
                    <i class="bi bi-instagram mx-2"></i>           -->
                    <!-- <div class="Social-media">
                        <a href="#"><font color="#007cc4"><i class="bi bi-whatsapp"></i></font></a>
                        <a href="#"><font color="#007cc4"><i class="bi bi-facebook"></i></font></a>
                        <a href="#"><font color="#007cc4"><i class="bi bi-linkedin"></i></font></a>
                        <a href="#"><font color="#007cc4"><i class="bi bi-instagram"></i></font></a>
                        <a href="#"><font color="#007cc4"><i class="bi bi-twitter"></i></font></a>
                      </div> -->
                    <div class="set rounded">
                        <a
                            href="https://wa.me/+919998692099"
                            class="social whatsapp"
                        ></a>
                        <a
                            href="https://www.facebook.com/3waytech"
                            class="social facebook"
                        ></a>
                        <a
                            href="https://twitter.com/3WayTech1"
                            class="social twitter"
                        ></a>
                        <a
                            href="https://www.instagram.com/3waytech/"
                            class="social instagram"
                        ></a>
                        <a
                            href="https://www.linkedin.com/company/3-way-technologies/"
                            class="social linked-in"
                        ></a>
                    </div>
                </div>
                <div class="col-lg-6 col-md-8 lh-1">
                    <p>© Proudly Owned by 3Way Technology</p>
                </div>
            </div>
        </div>
    </div>
</footer>
