<div id="showcase" class="show-case-section pt-100 pb-70">
    <div class="container">
        <div class="row" style="justify-content: center">
            <!-- <h2>Services I Provide for Clients</h2>
            <p>This feature is essential for any mobile app, so make sure you have it! These types of notifications can directly alert your user.</p> -->
            <div class="col-md-4">
                <div class="select mb-4 mt-2">
                    <select 
                        (change)="onSelect($event)"
                        class="cs-select cs-skin-elastic"
                    >
                        <option>Select Industry</option>
                        
                        <option
                            *ngFor="let industry of industryList"
                            value="{{ industry.id }}"
                            [selected] = "industry.id == industryId ? 'isSelected' : undefined"
                        >
                            {{ industry.name }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-4">
                <div class="select mb-4 mt-2"> 
                        <select (change)="onTecSelect($event)">
                        <option>Select Technology</option>
                        <option
                            *ngFor="let technology of filteredTechnologyList"
                            value="{{ technology.value }}"
                            [selected] = "technology.id == tecId ? 'isSelected' : undefined"
                        >
                            {{ technology.name }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <ng-container *ngFor="let row of filteredData ;index as i">
                <div
                    class="col-lg-4 col-md-6 col-sm-6" >
                    <div class="showcase-card">
                        <img src="{{ row.img }}" alt="showcase image" />
                        <div class="showcsae-text read-more" style="--line-clamp: 3">
                            <h3>{{ row.title }}</h3>
                            <input id="{{i + 1}}" type="checkbox" class="read-more__checkbox" aria-hidden="true">
                            <p class="read-more__text">{{ row.hover }} </p>
                            <label for="{{i + 1}}" class="read-more__label btn btn-danger" data-read-more="Read more" 
                            data-read-less="See less" aria-hidden="true"></label>
                        </div>
                        <!-- <div class="shoscase-hover">
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <h3>{{ row.title }}</h3>
                                    <p>{{ row.desc }}</p>
                                   <button class="btn btn-primary"><a href="{{row.url}}">blog</a></button> 
                                </div>
                            </div>
                        </div> -->
                        <!-- <div class="text-center mb-2">
                            <button type="button" class="btn btn-danger">Read more</button>
                          </div> -->
                    </div>
                </div>
            </ng-container>

            <section class="page_404" *ngIf="filteredData.length === 0 && !loading">
                <div class="container">
                    <div class="row">	
                    <div class="col-sm-12 ">
                    <div class="col-sm-10 col-sm-offset-1  text-center">
                    <div class="four_zero_four_bg">
                        <h1 class="text-center ">404</h1>
                    
                    
                    </div>
                    
                    <div class="contant_box_404">
                    <h3 class="h2">
                    this data is not available
                    </h3>
                    
                    <p>the page you are looking for not avaible!</p>
                    
                    <a routerLink="/Web-Development" class="link_404">Go to Home</a>
                </div>
                    </div>
                    </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>

