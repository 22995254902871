<nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied" ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>
    <div class="container">
        <a class="navbar-brand" routerLink="/" (click)="onClick('home')">
            <img src="assets/img/logo12.png" alt="logo" style="max-width: 7rem;">
        </a>
        <button class="navbar-toggler" type="button" (click)="toggleClass()">
            <span class="burger-menu">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ms-auto">
                <li class="nav-item"><span class="nav-link" routerLink="/" (click)="onClick('home')">Home</span></li>
                <li class="nav-item"><span class="nav-link" routerLink="/" (click)="onClick('services')">Services</span></li>
                <!-- <li class="nav-item"><span class="nav-link" (click)="onClick('about')">About Us</span></li> -->
                <li class="nav-item"><span class="nav-link" routerLink="/" (click)="onClick('testimonials')">Testimonials</span></li>
                <a href="https://3waytech.co/"> <li class="nav-item"> <span class="nav-link">Back To Site</span></li></a>
                <!-- <li class="nav-item"><span class="nav-link" (click)="onClick('pricing')">Pricing</span></li> -->
            </ul>
            <ul class="navbar-nav ms-auto for-responsive">
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('home')">Home</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('services')">Services</span></li>
                <!-- <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('about')">About Us</span></li>  -->
                <!-- <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('pricing')">Pricing</span></li> -->
                <!-- <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('testimonials')">Testimonials</span></li> -->
            </ul>
            <div class="navbar-btn">
                <a href="https://3waytech.co/contacts/">Get In touch</a>
            </div>
        </div>
    </div>
</nav>




<!-- <iframe width="1536" height="864" src="https://www.youtube.com/embed/WQ119jAN4Xo" title="10 Best After Effects Templates for YouTube [2021]" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->