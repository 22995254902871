<app-app-banner></app-app-banner>

<!-- <app-app-why-choose></app-app-why-choose>

<app-app-features></app-app-features>
<app-portfolio-services></app-portfolio-services>

<app-app-screenshots></app-app-screenshots>

<app-app-how-to-use></app-app-how-to-use>

<app-app-pricing></app-app-pricing>

<app-app-testimonials></app-app-testimonials> -->

<app-portfolio-services></app-portfolio-services>

<!-- <app-portfolio-software></app-portfolio-software>

<app-portfolio-showcase></app-portfolio-showcase> -->

<app-portfolio-partner></app-portfolio-partner>

<app-portfolio-testimonials></app-portfolio-testimonials>

<app-portfolio-contact></app-portfolio-contact>