import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-portfolio-services",
    templateUrl: "./portfolio-services.component.html",
    styleUrls: ["./portfolio-services.component.scss"],
})
export class PortfolioServicesComponent implements OnInit {

    constructor() {}

    ngOnInit(): void {
    }

   
}
