<div id="showcase" class="show-case-section pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="showcase-card">
                    <img src="{{ Userdata?.img }}" alt="showcase image" />
                    <div class="showcsae-text">
                        <h3>{{ Userdata?.title }}</h3>
                        <p>{{ Userdata?.hover }}</p>
                    </div>
                    <div class="shoscase-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <h3>{{ Userdata?.title }}</h3>
                                <p>{{ Userdata?.desc }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
