<div id="testimonials" class="testimonial-style-three pb-100">
    <div class="container">
        <div class="section-title">
            <h2>Peoples Quotes About Me</h2>
            <p>App Store and Google Play Store gift cards are interchangeable, so no matter which one you received  you can use the credit for apps.</p>
        </div>
    </div>
    <div class="testimonial-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 offset-lg-3 col-md-10 offset-md-1 col-sm-10 offset-sm-1">
                    <div class="testimonial-text-slider slider-for text-center">
                        <owl-carousel-o [options]="testimonialTextSlides">
                            <ng-template carouselSlide>
                                <div class="sin-testiText">
                                    <img src="assets/img/personal-portfolio/testimonial/pic3.jpg" alt="client image">
                                    <h2>Vinh </h2>
                                    <span>Client</span>
                                    <p>It's perfect for when you want to show your appreciation for a team member, the 2nd floor, 3rd floor...everyone! 
                                        The interface is sleek and easy to use and it offers a variety of creative ways to thank people in your team.</p>
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="sin-testiText">
                                    <img src="assets/img/personal-portfolio/testimonial/pic4.jpg" alt="client image">
                                    <h2>Ajulo </h2>
                                    <span>UX Designer</span>
                                    <p>I've worked with countless designers over the course of my career and 3ways stands out as one of the few that cares about creativity and not just creating something to make it seem good. 
                                        He has a genuine passion for what he does and it really shines through in his work.</p>                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="sin-testiText">
                                    <img src="assets/img/personal-portfolio/testimonial/pic2.jpg" alt="client image">
                                    <h2>Marielia </h2>
                                    <span>Expert</span>
                                    <p>It's been really helpful in figuring out who the best people are for certain tasks. I love that it has a chat feature too because it makes our meetings more interactive and less boring.</p>
                                </div>
                            </ng-template>
                            <!-- <ng-template carouselSlide>
                                <div class="sin-testiText">
                                    <img src="assets/img/personal-portfolio/testimonial/4.png" alt="client image">
                                    <h2>Eddey</h2>
                                    <span>Treaveller</span>
                                    <p>I am really enjoying this with him. I was surprised that he did complete all my recommendataion in time. Every time I will suggest him to do work with John.</p>
                                </div>
                            </ng-template> -->
                            <ng-template carouselSlide>
                                <div class="sin-testiText">
                                    <img src="assets/img/personal-portfolio/testimonial/pic1.jpg" alt="client image">
                                    <h2>William</h2>
                                    <span>Client</span>
                                    <p>I just wanted to take a second to acknowledge 3ways and their product. I have been using it for a while now, but never took the time to write a review. 
                                        It's such an easy way to show appreciation for your team. I would recommend 3ways to any company looking for work appreciation!</p>
                                </div>
                            </ng-template>
                        </owl-carousel-o>
                    </div>   
                </div>
            </div>
        </div>
    </div>
</div>