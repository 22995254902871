<div class="trusted-company ptb-100">
    <div class="container">
        <div class="section-title text-center">
            <h2>Worked With</h2>
            <p>This feature is essential for any mobile app, so make sure you have it! These types of notifications can directly alert your user.</p>
        </div>
        <div class="company-slider">
            <owl-carousel-o [options]="companySlides">
                <ng-template carouselSlide>
                    <a target="_blank">
                        <img src="assets/img/sass-landing/company/logo1.png" alt="company logo">
                    </a>
                </ng-template>
                <ng-template carouselSlide>
                    <a target="_blank">
                        <img src="assets/img/sass-landing/company/logo2.png" alt="company logo">
                    </a>
                </ng-template>
                <ng-template carouselSlide>
                    <a target="_blank">
                        <img src="assets/img/sass-landing/company/logo4.png" alt="company logo">
                    </a>
                </ng-template>
                <ng-template carouselSlide>
                    <a target="_blank">
                        <img src="assets/img/sass-landing/company/logo5.png" alt="company logo">
                    </a>
                </ng-template>
                <ng-template carouselSlide>
                    <a target="_blank">
                        <img src="assets/img/sass-landing/company/logo6.png" alt="company logo">
                    </a>
                </ng-template>
                
            </owl-carousel-o>
        </div>
    </div>
</div>