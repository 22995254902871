<div id="services" class="service-style-three pb-70 mt-4">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6" routerLink="/UI-UX">
                <div class="service-items wow fadeInUp" data-wow-duration="1s">
                    <i class="flaticon-ui"></i>
                    <h3>UI/UX Design</h3>
                    <p>
                        When it comes after the noun, the ar djective usually
                        doesn't get a hyphen. So we say an easy to remember.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6" routerLink="/Web-Development">
                <div
                    class="service-items wow fadeInUp"
                    data-wow-duration="1s"
                    data-wow-delay=".2"                  
                >
                    <i class="flaticon-web-programming"></i>
                    <h3>Web Development</h3>
                    <p>
                        When it comes after the noun, the ar djective usually
                        doesn't get a hyphen. So we say an easy to remember.
                    </p>
                </div>
            </div>
                <div class="col-lg-4 col-sm-6" routerLink="/App-Development">
                    <div
                        class="service-items wow fadeInUp"
                        data-wow-duration="1s"
                        data-wow-delay=".3"
                    >
                        <i class="flaticon-medical-app"></i>
                        <h3>App Development</h3>
                        <p>
                            When it comes after the noun, the ar djective usually
                            doesn't get a hyphen. So we say an easy to remember.
                        </p>
                    </div>
                </div>
            <div class="col-lg-4 col-sm-6" routerLink="/graphics">
                <div
                    class="service-items wow fadeInUp"
                    data-wow-duration="1s"
                    data-wow-delay=".4"
                >
                    <i class="flaticon-writing"></i>
                    <h3>Graphic Design</h3>
                    <p>
                        Create Professional Layouts, Logos, Photo Effects,
                        Drawings, 3D Vector Graphics. Enrol Now.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6" routerLink="/Digital-marketing">
                <div
                    class="service-items wow fadeInUp"
                    data-wow-duration="1s"
                    data-wow-delay=".5"
                >
                    <i class="flaticon-java"></i>
                    <h3>Digital Marketing</h3>
                    <p>
                        When it comes after the noun, the ar djective usually
                        doesn't get a hyphen. So we say an easy to remember.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
            <a href="https://3waytech.co/outsource/">
                <div
                    class="service-items wow fadeInUp"
                    data-wow-duration="1s"
                    data-wow-delay=".6"
                >
                    <i class="flaticon-video"></i>
                    <h3>Dedicated Developers</h3>
                    <p>
                        When it comes after the noun, the ar djective usually
                        doesn't get a hyphen. So we say an easy to remember.
                    </p>
                </div>
            </a>
            </div>
        </div>
    </div>
</div>
